const FILE_EXTENSION_REGEX = /(?:\.([^.]+))?$/; // Regular expression to capture file extension

export const downloadFileFromUrl = async (url, fileName = '') => {
  if (!url) {
    return null;
  }

  const extension = FILE_EXTENSION_REGEX.exec(url);

  if (!extension) {
    return;
  }

  const data = await fetch(url);

  const blob = await data.blob();

  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);

  link.download = `${fileName}.${extension[1]}`;

  link.click();
};

export const getFileNameFromUrl = (
  url,
  { withExtension = false, shouldDecodeURI = false } = {},
) => {
  if (!url) {
    return null;
  }

  let fileName = url.substring(url.lastIndexOf('/') + 1, url.lastIndexOf('.'));
  const extension = FILE_EXTENSION_REGEX.exec(url)[1];

  if (shouldDecodeURI) {
    fileName = decodeURIComponent(fileName);
  }

  return withExtension ? `${fileName}.${extension}` : fileName;
};

export const getFileExtension = (url) => {
  if (!url) {
    return null;
  }

  const extension = FILE_EXTENSION_REGEX.exec(url);

  if (!extension) {
    return '';
  }

  return extension[1];
};
