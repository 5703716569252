import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: fit-content;
  min-height: 24px;

  padding: 4px 8px;

  display: flex;
  align-items: center;

  border-radius: 4px;
  font: ${(props) => props.theme.label.font};

  ${(props) => {
    if (props.type === 'tertiary') {
      if (props.color === 'green') {
        return css`
          background-color: ${(props) =>
            props.isDisabled
              ? props.theme.colors.greys.lightMedium
              : props.theme.colors.greys.lightest};
          border: 2px solid ${props.theme.colors?.infoGreen};
          color: ${props.theme.colors?.infoGreen};
        `;
      }

      if (props.color === 'red') {
        return css`
          background-color: ${(props) =>
            props.isDisabled
              ? props.theme.colors.greys.lightMedium
              : props.theme.colors.greys.lightest};
          border: 2px solid ${props.theme.colors?.infoRed};
          color: ${props.theme.colors?.infoRed};
        `;
      }

      if (props.color === 'orange') {
        return css`
          background-color: ${(props) =>
            props.isDisabled
              ? props.theme.colors.greys.lightMedium
              : props.theme.colors.greys.lightest};
          border: 2px solid ${props.theme.colors?.infoOrange};
          color: ${props.theme.colors?.infoOrange};
        `;
      }

      if (props.color === 'black') {
        return css`
          background-color: ${(props) =>
            props.isDisabled
              ? props.theme.colors.greys.lightMedium
              : props.theme.colors.greys.lightest};
          border: 2px solid ${props.theme.colors?.greys?.darkest};
          color: ${props.theme.colors?.greys?.darkest};
        `;
      }

      if (props.color === 'grey') {
        return css`
          background-color: ${(props) =>
            props.isDisabled
              ? props.theme.colors.greys.lightMedium
              : props.theme.colors.greys.lightest};
          border: 2px solid ${props.theme.colors?.greys?.dark};
          color: ${props.theme.colors?.greys?.dark};
        `;
      }
    }

    if (props.type === 'secondary') {
      if (props.color === 'green') {
        return css`
          background: ${(props) =>
            props.isDisabled
              ? props.theme.colors.greys.lightMedium
              : props.theme.colors?.infoDarkGreen};
          color: ${props.theme.colors?.greys?.lightest};
        `;
      }

      if (props.color === 'orange') {
        return css`
          background: ${(props) =>
            props.isDisabled
              ? props.theme.colors.greys.lightMedium
              : props.theme.colors?.infoOrange};
          color: ${props.theme.colors?.greys?.lightest};
        `;
      }

      if (props.color === 'red') {
        return css`
          background: ${(props) =>
            props.isDisabled ? props.theme.colors.greys.lightMedium : props.theme.colors?.infoRed};
          color: ${props.theme.colors?.greys?.lightest};
        `;
      }
    }
  }}
`;
