import { APIcaller } from './APIcaller';

const getInvoiceControlList = (clientId, supplierIds) =>
  APIcaller.apiPost(`/invoice-control/list`, { clientId, supplierIds });

const exportInvoiceControlsDetails = (invoiceControlListIds) =>
  APIcaller.apiPost(`/invoice-control/export-details`, { invoiceControlListIds });

const getAvailableCredits = (clientId) =>
  APIcaller.apiGet(`/invoice-control/credits?clientId=${clientId}`);

const importInvoiceControlLists = (clientId, supplierId, storeId, invoiceUrls) =>
  APIcaller.apiPost(`/invoice-control/import`, { clientId, supplierId, storeId, invoiceUrls });

const getById = (id) => APIcaller.apiGet(`/invoice-control/${id}`);

const getOcerizationById = (id) => APIcaller.apiGet(`/invoice-control/${id}/export-ocerization`);

const analyseAndExportInvoiceControlListDetails = (id) =>
  APIcaller.apiGet(`/invoice-control/${id}/export-details`);

const updateById = (id, fields) => APIcaller.apiPut(`/invoice-control/${id}`, { fields });

const resetOrderInvoiceAssociation = (id, storeId, supplierId) =>
  APIcaller.apiPost(`/invoice-control/${id}/reset`, { storeId, supplierId });

const deleteById = (id) => APIcaller.apiDelete(`/invoice-control/${id}`);

const getOrdersByInvoiceControlListId = (id) => APIcaller.apiGet(`/invoice-control/${id}/orders`);

const getAvailableOrdersToAssociate = (storeId, supplierId) =>
  APIcaller.apiGet(`/invoice-control/available-orders?storeId=${storeId}&supplierId=${supplierId}`);

const deleteOrderByInvoiceControlListId = (id, orderId) =>
  APIcaller.apiDelete(`/invoice-control/${id}/orders/${orderId}`);

const addOrdersByInvoiceControlListId = (id, orderIds) =>
  APIcaller.apiPost(`/invoice-control/${id}/orders`, { orderIds });

export default {
  getById,
  updateById,
  deleteById,
  getOcerizationById,
  analyseAndExportInvoiceControlListDetails,
  getAvailableCredits,
  getInvoiceControlList,
  exportInvoiceControlsDetails,
  importInvoiceControlLists,
  getOrdersByInvoiceControlListId,
  addOrdersByInvoiceControlListId,
  deleteOrderByInvoiceControlListId,
  getAvailableOrdersToAssociate,
  resetOrderInvoiceAssociation,
};
