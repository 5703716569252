import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState, useEffect } from 'react';

// Actions
import { loading, loadingSuccess } from '@actions/loading';
import { showErrorMessage, showSuccessMessage } from '@actions/messageconfirmation';

// Form
import { FIELDS_TO_CHECK, getInputs } from './utils/form';
import { yupResolver } from '@hookform/resolvers/yup';
import GenericForm, { buildSchema } from '@commons/GenericForm';

// Components
import { Button } from '@commons/utils/styledLibraryComponents';

// Commons
import { DATE_DISPLAY_FORMATS } from '@commons/DatePickers/constants';

// Services
import invoiceService from '@services/invoice';

// Styles
import { INVOICE_STATUS } from '@src/routes/invoice/invoiceControls/utils/constants';
import {
  ModalBackground,
  ModalContainer,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Text,
  Icon,
} from './styledComponents';

const REGEX_FILENAME = /([^\/]+(?:.pdf|.PDF|.csv|.CSV)$)/;

export const DeliveryInvoiceModal = (props) => {
  const {
    // Props
    close,
    invoiceControlListId,
    // Dispatch methods / props
    currency,
    pageLoaded,
    pageLoading,
    showErrorMessage,
    showSuccessMessage,
  } = props;

  const isTablet = window.innerWidth <= 1280;

  const [invoice, setInvoice] = useState({});
  const [isInvoiceLocked, setIsInvoiceLocked] = useState(false);

  // Form states
  const [inputs, setInputs] = useState(getInputs({ currency }));
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSaveAlreadyTriggered, setIsSaveAlreadyTriggered] = useState(false);

  const form = useForm({
    defaultValues: {},
    resolver: yupResolver(buildSchema(getInputs({ invoice, currency }))),
  });

  const formFields = useWatch({
    control: form.control,
  });

  // Fetch invoice info
  useEffect(() => {
    if (!invoiceControlListId) {
      return;
    }

    getInvoiceById(invoiceControlListId);
  }, [invoiceControlListId]);

  // Initialize form values from invoice details
  useEffect(() => {
    if (isEmpty(invoice)) {
      return;
    }

    const [fileName] = invoice.link.match(REGEX_FILENAME) || [];

    setIsInvoiceLocked(invoice.status === INVOICE_STATUS.FAILURE);

    setInputs(getInputs({ invoice, currency }));

    form.setValue('fileName', fileName);
    form.setValue('total', invoice.total);
    form.setValue('number', invoice.number);
    form.setValue('date', invoice.date ? moment(invoice.date) : null);
  }, [invoice]);

  // Check whether form is dirty
  useEffect(() => {
    (async () => {
      const fieldsValidation = await form.trigger(FIELDS_TO_CHECK);

      setIsSaveDisabled(!fieldsValidation);

      const isFormDirty = get(form, 'formState.isDirty', false);

      const dirtyFields = Object.keys(get(form, 'formState.dirtyFields', {}));

      const hasAtLeastOneDifferentField = dirtyFields.some(
        // not being sensitive to type change (case on total property)
        (field) => invoice[field] != formFields[field],
      );

      if (isFormDirty && hasAtLeastOneDifferentField) {
        setIsSaveDisabled(false);

        return;
      }

      setIsSaveDisabled(true);
    })();
  }, [formFields]);

  const getInvoiceById = async (invoiceId) => {
    pageLoading();

    try {
      const result = await invoiceService.getById(invoiceId);

      setInvoice(result);
    } catch {
      setInvoice({});

      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FETCH_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const validateForm = async () => {
    const fieldsValidation = await form.trigger();

    setIsSaveAlreadyTriggered(true);

    if (!fieldsValidation) {
      setIsSaveDisabled(true);

      return;
    }

    form.handleSubmit(save)();
  };

  const save = async () => {
    pageLoading();

    const fieldsToUpdate = {
      date: formFields.date.format(DATE_DISPLAY_FORMATS.DASHED_YEAR_MONTH_DAY),
      total: formFields.total,
      number: formFields.number,
    };

    try {
      await invoiceService.updateById(invoice.id, fieldsToUpdate);

      showSuccessMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.UPDATE_SUCCESS'));

      closeModal();
    } catch {
      showErrorMessage(i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.UPDATE_ERROR'));
    } finally {
      pageLoaded();
    }
  };

  const closeModal = () => {
    close();
  };

  return (
    <ModalBackground isTablet={isTablet}>
      <ModalContainer isTablet={isTablet}>
        <ModalHeader>
          <Text title="true">{i18next.t('ORDERS.ORDERS.FORM_INVOICE_INFOS')}</Text>
          <Icon
            alt="close"
            height="14px"
            src="/images/inpulse/close-black-small.svg"
            width="14px"
            interactive
            onClick={closeModal}
          />
        </ModalHeader>
        <ModalContent isTablet={isTablet}>
          <GenericForm
            customStyle={{
              gap: '24px',
              height: 'inherit',
              flexWrap: 'wrap',
              position: 'absolute',
              alignItems: 'flex-start',
            }}
            form={form}
            inputs={inputs}
            isEditionAllowed={!isInvoiceLocked}
            shouldDisplayError={isSaveAlreadyTriggered}
            shouldDisplayErrorMessage={false}
          />
        </ModalContent>
        <ModalFooter>
          <Button
            buttonCustomStyle={{ marginLeft: 24 }}
            color={'inpulse-outline'}
            handleClick={closeModal}
            icon="/images/inpulse/close-black-small.svg"
            label={i18next.t('GENERAL.CANCEL')}
          />
          <Button
            buttonCustomStyle={{ marginLeft: 24 }}
            color={'inpulse-default'}
            handleClick={validateForm}
            icon="/images/inpulse/check-white-small.svg"
            isDisabled={isSaveDisabled}
            label={i18next.t('GENERAL.SAVE')}
          />
        </ModalFooter>
      </ModalContainer>
    </ModalBackground>
  );
};

const mapStateToProps = (state) => ({
  currency: state.baseReducer.currency,
});

const mapDispatchToProps = (dispatch) => ({
  showErrorMessage: (message) => {
    dispatch(showErrorMessage(message));
  },
  showSuccessMessage: (message) => {
    dispatch(showSuccessMessage(message));
  },
  pageLoading: () => {
    dispatch(loading());
  },
  pageLoaded: () => {
    dispatch(loadingSuccess());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryInvoiceModal);
