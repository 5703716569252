import * as yup from 'yup';
import i18next from 'i18next';

import { INPUT_TYPES } from '@commons/constants/inputType';
import { INPUT_WIDTH } from '@commons/utils/styledLibraryComponents';

const INPUTS = {
  FILENAME: 'fileName',
  DATE: 'date',
  NUMBER: 'number',
  TOTAL: 'total',
};

export const FIELDS_TO_CHECK = ['number', 'date', 'total'];

export const getInputs = ({ invoice = {}, currency }) => [
  {
    name: INPUTS.NUMBER,
    type: INPUT_TYPES.TEXT,
    width: INPUT_WIDTH.LARGE,
    label: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_NUMBER_FIELD'),
    isRequired: false,
  },
  {
    name: INPUTS.DATE,
    width: INPUT_WIDTH.LARGE,
    label: `${i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_DATE_FIELD')} :`,
    type: INPUT_TYPES.SINGLE_DATEPICKER,
    showClearDate: false,
    rule: yup.date().nullable(),
  },
  {
    name: INPUTS.TOTAL,
    type: INPUT_TYPES.TEXT,
    width: INPUT_WIDTH.LARGE,
    label: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_TOTAL_FIELD', {
      currency: currency && currency.alphabeticCode,
    }),
    rule: yup.lazy((value) =>
      value === ''
        ? yup.string()
        : yup
            .number()
            .nullable()
            .min(
              0,
              i18next.t('ADMIN.SUPPLIER_PRODUCTS.DETAIL_FORM_FIELD_GREATER_THAN_ERROR', {
                field: i18next.t(
                  'INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_TOTAL_FIELD',
                  { currency: currency && currency.alphabeticCode },
                ),
                value: 0,
              }),
            ),
    ),
    isRequired: false,
  },
  {
    name: INPUTS.FILENAME,
    type: INPUT_TYPES.TEXT,
    width: INPUT_WIDTH.LARGE,
    label: i18next.t('INVOICE.INVOICE_CONTROL_DETAILS.FORM_INFORMATION_INVOICE_LINK_FIELD'),
    isDisabled: () => true,
    link: invoice.link
      ? {
          onClick: () => {
            window.open(invoice.link, '_blank');
          },
          customStyle: { marginLeft: '4px' },
        }
      : null,
  },
];

export default {
  getInputs,
};
