import { getActionAuthorization } from '../commons/utils';

const ORDER_ACTIONS_RESOURCE = {
  VIEW: 'order:view',

  CREATE_DRAFT: 'order:create',

  SEND: 'order:send',
  CHANGE_STATUS_TO_SENT: 'order:send:bypass-supplier',
  CHANGE_STATUS_TO_DRAFT: 'order:send:cancel',

  EDIT_DRAFT: 'order:edit:draft',
  EDIT_SENT_OR_RECEIVED: 'order:edit:sent',
  CHANGE_DELIVERY_DATE: 'order:edit:delivery-date',
  SET_CREDIT_REQUEST_PROCESSED: 'order:edit:credit-request-processed',

  DELETE_DRAFT: 'order:delete:draft',
  DELETE_SENT: 'order:delete:sent',

  RECEIVE_ORDER_SEND_CREDIT_REQUEST: 'order:receive',
  CANCEL_RECEPTION: 'order:receive:cancel',
  VALIDATE_CREDIT_REQUEST_WITHOUT_MAIL: 'order:receive:bypass-supplier',
};

export const canCreateDraftOrder = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.CREATE_DRAFT);

export const canSendOrder = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.SEND);

export const canChangeOrderStatusToSent = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.CHANGE_STATUS_TO_SENT);

export const canChangeOrderStatusToDraft = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.CHANGE_STATUS_TO_DRAFT);

export const canEditDraftOrder = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.EDIT_DRAFT);

export const canEditSentOrReceivedOrder = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.EDIT_SENT_OR_RECEIVED);

export const canChangeOrderDeliveryDate = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.CHANGE_DELIVERY_DATE);

export const canSetCreditRequestProcessed = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.SET_CREDIT_REQUEST_PROCESSED);

export const canDeleteDraftOrder = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.DELETE_DRAFT);

export const canDeleteSentOrder = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.DELETE_SENT);

export const canReceiveOrderAndSendCreditRequest = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.RECEIVE_ORDER_SEND_CREDIT_REQUEST);

export const canCancelOrderReception = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.CANCEL_RECEPTION);

export const canValidateCreditRequestWithoutMail = (actions) =>
  getActionAuthorization(actions, ORDER_ACTIONS_RESOURCE.VALIDATE_CREDIT_REQUEST_WITHOUT_MAIL);
